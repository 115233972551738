import * as React from "react"

export default ({
  column: { filterValue = [], preFilteredRows, setFilter, id },
}) => {
  const [min, max] = React.useMemo(() => {
    let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
    preFilteredRows.forEach(row => {
      min = Math.min(row.values[id], min)
      max = Math.max(row.values[id], max)
    })
    return [min, max]
  }, [id, preFilteredRows])

  return (
    <div
      style={{
        display: "flex",
      }}
    >
      <input
        value={filterValue[0] || ""}
        type="text"
        onChange={e => {
          const val = e.target.value
          setFilter((old = []) => [val, old[1]])
        }}
        placeholder={`${Intl.DateTimeFormat("en-gb", {
          month: "numeric",
          day: "numeric",
        }).format(min)}`}
        style={{
          width: "70px",
          marginRight: "0.5rem",
        }}
      />
      to
      <input
        value={filterValue[1] || ""}
        type="text"
        onChange={e => {
          const val = e.target.value
          setFilter((old = []) => [old[0], val])
        }}
        placeholder={`${Intl.DateTimeFormat("en-gb", {
          month: "numeric",
          day: "numeric",
        }).format(max)}`}
        style={{
          width: "70px",
          marginLeft: "0.5rem",
        }}
      />
    </div>
  )
}
