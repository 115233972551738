import DefaultColumnFilter from "../../../../components/TableV2/DefaultColumnFilter"
import { Link } from "gatsby"
import React, { useMemo } from "react"
import { FormattedDate } from "react-intl"
import styled from "styled-components"
import { colors } from "../../../../theme"
import NumberRangeColumnFilter from "../../../../components/TableV2/NumberRangeColumnFilter"
import SelectColumnFilter from "../../../../components/TableV2/SelectColumnFilter"
import TooltipColumFilter from "../../../../components/TableV2/TooltipColumFilter"
import DateRangeColumnFilter from "../../../../components/TableV2/DateRangeColumnFilter"

const BaseCell = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  a {
    text-decoration: underline;
  }
`

const useTableSettings = () => {
  const columns = useMemo(
    () => [
      {
        Header: "ID",
        disableFilters: true,
        accessor: "id",
        Cell: ({ cell: { value } }) => (
          <BaseCell>
            <Link to={`/order/${value}`}>{value}</Link>
          </BaseCell>
        ),
        Aggregated: ({
          cell: {
            row: { groupByID, subRows },
          },
        }) => `${subRows.length} Unique Names`,
      },
      {
        Header: "Customer",
        accessor: "customer",
        disableFilters: true,
        filter: (rows, columnIds, value) => {
          try {
            return rows.filter(r => {
              return new RegExp(value).test(r.values.customer.email)
            })
          } catch (err) {
            return rows
          }
        },
        Filter: DefaultColumnFilter,
        Cell: ({ cell: { value } }) => (
          <BaseCell
            style={{
              whiteSpace: "nowrap",
              justifyContent: "flex-start",
              textOverflow: "ellipsis",
              maxWidth: "230px",
              overflow: "hidden",
            }}
          >
            {value._id ? (
              <Link to={`/customer/${value.email}`}>{value.email}</Link>
            ) : (
              value.email
            )}
          </BaseCell>
        ),
        Aggregated: ({
          cell: {
            row: { groupByID, subRows },
          },
        }) => `${subRows.length} Unique Names`,
      },
      {
        Header: "Status",
        accessor: "status",
        disableFilters: true,
        filter: "include",
        Filter: props => (
          <TooltipColumFilter>
            <SelectColumnFilter {...props} />
          </TooltipColumFilter>
        ),
        Cell: ({ cell: { value } }) => <BaseCell>{value}</BaseCell>,
        Aggregated: ({
          cell: {
            row: { groupByID, subRows },
          },
        }) => `${subRows.length} Unique Names`,
      },
      {
        Header: "Paid",
        accessor: "paid",
        disableFilters: true,
        filter: "include",
        Filter: props => (
          <TooltipColumFilter>
            <SelectColumnFilter {...props} />
          </TooltipColumFilter>
        ),
        Cell: ({ cell: { value } }) => (
          <BaseCell
            style={{
              padding: "0 12px",
              display: "inline-flex",
              borderRadius: "8px",
              color: "#fff",
              backgroundColor: value ? colors.green : colors.red,
            }}
          >
            {value ? "Yes" : "No"}
          </BaseCell>
        ),
        Aggregated: ({
          cell: {
            row: { groupByID, subRows },
          },
        }) => `${subRows.length} Unique Names`,
      },
      {
        Header: "Shippable",
        accessor: "shippable",
        disableFilters: true,
        filter: "include",
        Filter: props => (
          <TooltipColumFilter>
            <SelectColumnFilter {...props} />
          </TooltipColumFilter>
        ),
        Cell: ({ cell: { value } }) => (
          <BaseCell
            style={{
              padding: "0 12px",
              borderRadius: "8px",
              display: "inline-flex",
              color: "#fff",
              backgroundColor: value ? colors.green : colors.red,
            }}
          >
            {value ? "Yes" : "No"}
          </BaseCell>
        ),
        Aggregated: ({
          cell: {
            row: { groupByID, subRows },
          },
        }) => `${subRows.length} Unique Names`,
      },
      {
        Header: "# Items",
        accessor: "nbItems",
        disableFilters: true,
        filter: "between",
        Filter: props => (
          <TooltipColumFilter>
            <NumberRangeColumnFilter {...props} />
          </TooltipColumFilter>
        ),
        Cell: ({ cell: { value } }) => <BaseCell>{value}</BaseCell>,
        Aggregated: ({
          cell: {
            row: { groupByID, subRows },
          },
        }) => `${subRows.length} Unique Names`,
      },
      {
        Header: "Total",
        accessor: "total",
        disableFilters: true,
        Filter: props => (
          <TooltipColumFilter>
            <NumberRangeColumnFilter {...props} />
          </TooltipColumFilter>
        ),
        filter: (rows, columnIds, value) => {
          try {
            return rows.filter(r => {
              const min = value[0] || 0
              const max = value[1] || Infinity
              return (
                r.values.total.unformatted > min &&
                r.values.total.unformatted < max
              )
            })
          } catch (err) {
            return rows
          }
        },
        Cell: ({ cell: { value } }) => <BaseCell>{value.formatted}</BaseCell>,
        Aggregated: ({
          cell: {
            row: { groupByID, subRows },
          },
        }) => `${subRows.length} Unique Names`,
      },
      {
        Header: "Date",
        accessor: "createdAt",
        disableFilters: true,
        filter: "between",
        Filter: props => (
          <TooltipColumFilter>
            <DateRangeColumnFilter {...props} />
          </TooltipColumFilter>
        ),
        Cell: ({ cell: { value } }) => (
          <div>
            <FormattedDate
              year="numeric"
              month="long"
              day="2-digit"
              hour="numeric"
              minute="numeric"
              value={value}
            />
          </div>
        ),
      },
    ],
    []
  )

  return {
    columns,
  }
}

export default useTableSettings
