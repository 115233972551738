import { useApolloClient } from "@apollo/client"
import useAsync from "../../../../hooks/useAsync"
import GET_ORDERS from "./graphql/getOrders.graphql"

const useData = () => {
  const client = useApolloClient()
  const {
    execute: fetchOrders,
    data: currentOrders,
    loading: loadingOrders,
  } = useAsync({
    asyncFunc: async params => {
      const { data } = await client.query({
        query: GET_ORDERS,
        variables: {
          ...params,
        },
        fetchPolicy: "network-only",
        errorPolicy: "all",
      })
      return data.shop_orders
    },
    immediate: false,
    funcParams: {
      skip: 0,
      limit: 30,
    },
    initialData: { number: 0, data: [] },
  })

  return {
    loadingOrders,
    currentOrders,
    fetchOrders,
  }
}

export default useData
