import { Button } from "nzk-react-components"
import React, { useState } from "react"
import styled from "styled-components"
import { colors } from "../../../theme"

const Wrapper = styled.div`
  position: relative;
`

const Trigger = styled.div`
  * {
    color: #fff !important;
  }
`

const Tooltip = styled.div`
  position: absolute;
  top: 100%;
  padding: 20px;
  background-color: #ebebeb;
  z-index: 101;
  border: 4px solid ${colors.indigo};
`

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
`
export default props => {
  const [shown, setShown] = useState(false)

  return (
    <Wrapper>
      <Trigger>
        <Button
          theme="primary"
          size="x-small"
          onClick={() => setShown(d => !d)}
        >
          Filter
        </Button>
      </Trigger>
      {shown && <Overlay onClick={() => setShown(false)} />}
      {shown && (
        <Tooltip
          onClick={e => {
            e.preventDefault()
            e.stopPropagation()
          }}
        >
          {props.children}
        </Tooltip>
      )}
    </Wrapper>
  )
}
