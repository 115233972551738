import React from 'react'
import OrdersPage from '../components/pages/Shop/OrdersPage'

const OrdersPageExported = (props) => {
  if (typeof window === "undefined") return null

  return <OrdersPage {...props} />
}

export default OrdersPageExported
