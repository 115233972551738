import React from "react"
import styled from "styled-components"

const Select = styled.select`
  font-size: 12px;
  height: 26px;
  flex: 1;
  padding: 0 4px;
  border-radius: 4px;
  outline: none;
  border: none;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  width: 100%;
  background-color: white;
`

const OPTIONS = {
  true: "Yes",
  false: "No",
}
function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const optionsSet = new Set()
    preFilteredRows.forEach(row => {
      optionsSet.add(row.values[id])
    })
    return Array.from(optionsSet)
  }, [id, preFilteredRows])

  // Render a multi-select box
  return (
    <Select
      value={filterValue}
      onChange={e => {
        setFilter(e.target.value || undefined)
      }}
    >
      <option value="">All</option>
      {options.map((option: any, i) => (
        <option key={i} value={option}>
          {OPTIONS[option] || option}
        </option>
      ))}
    </Select>
  )
}

export default SelectColumnFilter
