import debounce from 'lodash.debounce'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import Loader from '../../../../components/UI/Loader'
import Table from '../../../TableV2'
import useData from './useData'
import useTableSettings from './useTableSettings'

const Wrapper = styled.div`
  position: relative;
  overflow: auto;
`

const LoadingWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
`

interface IProps {

}

const OrdersPage = (props: IProps) => {
  const { currentOrders, fetchOrders, loadingOrders } = useData()
  const { columns } = useTableSettings()
  const queryParams = typeof window !== 'undefined'
    ? new URLSearchParams(window.location.search)
    : null

  const [pageIndex, setPageIndex] = useState(parseInt(queryParams?.get('page') || '0', 10))
  const [pageSize, setPageSize] = useState(10)
  const [filters, setFilters] = useState({ paid: true })

  useEffect(() => {
    window.history.replaceState({}, '',`/orders?page=${pageIndex}`)
  }, [pageIndex])

  const nbOrders = useMemo(() => {
    return currentOrders.number
  }, [currentOrders])

  const pageCount = useMemo(() => {
    return Math.floor(nbOrders / pageSize) + 1
  }, [nbOrders, pageSize])

  const fetchPage = useCallback(({ skip, limit }) => {
    setPageSize(limit)
    fetchOrders({ skip, limit, filters })
  }, [])

  useEffect(() => {
    setPageSize(pageSize)
    fetchOrders({ skip: pageIndex * pageSize, limit: pageSize, filters })
  }, [])

  const rows = React.useMemo(() => {
    return (currentOrders?.data || []).map(order => {
      const unformattedTotal = order.lineItems.reduce((acc, s) => {
        return acc + s.quantity * s.price_data.unit_amount
      }, 0) / 100
    
      return {
        id: order._id,
        customer: { _id: order.user?._id, email: order.user?.email || order.customerEmail },
        paid: order.paid,
        archived: order.archived,
        createdAt: order.createdAt,
        shippable: order.subOrders.filter(s => !s.warehouseOrderId || (s.warehouse === 'CHARLESWORTH' && s.status !== 'CREATED')).length === 0,
        total: {
          formatted: new Intl.NumberFormat('en-us',
            { currency: order.lineItems[0].price_data.currency, style: "currency"})
            .format(unformattedTotal),
          unformatted: unformattedTotal,
        },
        status: order.status,
        nbItems: (order.lineItems || []).reduce((acc, l) => acc + l.quantity, 0)
      }
    })
  }, [currentOrders, loadingOrders])

  return <Wrapper>
    <Table columns={columns} data={rows} tableOptions={{
      manualPagination: true,
    }} fetchPage={fetchPage} pageCount={pageCount} />
    { loadingOrders && <LoadingWrapper>
      <Loader />
    </LoadingWrapper> }
  </Wrapper>
}

export default OrdersPage
